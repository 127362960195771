import React from 'react';
import HomeFactSingleItem from '../../../../components/HomeFactSingleItem/HomeFactSingleItem';

const FactArea = () => {
   return (
      <>
         <section className="tp-fact-area theme-dark-bg pt-75 pb-20">
            <div className="container">
               <div className="row">
                  <HomeFactSingleItem icon="shuttle" fact_num="125+" title="Bridal Makeups" />
                  <HomeFactSingleItem icon="broom" fact_num="100+" title="Students" />
                  <HomeFactSingleItem icon="selection" fact_num="300+" title="Satisfied Customers" />
                  <HomeFactSingleItem icon="badge" fact_num="12+" title="International Awards" />
               </div>
            </div>
         </section>
      </>
   );
};

export default FactArea;