import React from 'react';
import { Link } from 'react-router-dom';

const AboutArea = () => {
   return (
      <>
         <section className="tp-about-area position-relative pt-120 pb-90 fix">
            {/* <div className="tp-about-shape">
               <img src="assets/img/about/about-shape-1.jpg" className="img-fluid" alt="img not found"/>
            </div> */}
            <div className="container">
               <div className="row justify-content-xl-between justify-content-md-center">
                  <div className="col-xl-5 col-12">
                     <div className="tp-about-img z-index wow fadeInUp" data-wow-delay=".3s">
                        <img src="assets/img/about/about-img-1.jpg" alt="img not found"/>
                     </div>
                  </div>
                  <div className="col-xl-6 col-md-10">
                     <div className="tp-about-text z-index wow fadeInUp" data-wow-delay=".6s">
                        <div className="section-title-wrapper mb-30">
                           <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                              About our Company</h5>
                           <h4 className="tp-section-title">Prakruthie International<br/>Makeup and Hair Academy</h4>
                        </div>
                        <p className="mb-40">Pakruthie International Makeup and Hair Academy is a premier institution dedicated to the art and science of makeup and hairstyling, mehandi and nail art. Founded with a passion for enhancing natural beauty while fostering creativity, this academy has established itself as a leading name in the beauty industry. Located in a vibrant urban setting, it offers comprehensive training programs led by experienced professionals in the field.</p>
                        <div className="row mb-10">
                           <div className="col-sm-6">
                              <div className="tp-about-service mb-30">
                                 <div className="tp-about-service-icon yellow-circle-shape mb-15">
                                    <i className="flaticon-snowfall"></i>
                                 </div>
                                 <div className="tp-about-service-text">
                                    <h4 className="tp-about-service-text-title mb-15 hover-theme-color">
                                       <Link to="/services">Prakruthie Academy</Link></h4>
                                    <p>At Prakruthie Makeup and Hair Academy, students are exposed to a diverse range of makeup techniques, from bridal and special effects makeup to fashion and editorial looks.It is a premier institution dedicated to the art and science of makeup and hairstyling, mehandi and nail art and sell cosmetics with product knowledge</p>
                                 </div>
                              </div>
                           </div>
                           <div className="col-sm-6">
                              <div className="tp-about-service mb-30">
                                 <div className="tp-about-service-icon yellow-circle-shape mb-15">
                                    <i className="flaticon-business-and-trade"></i>
                                 </div>
                                 <div className="tp-about-service-text">
                                    <h4 className="tp-about-service-text-title mb-15 hover-theme-color">
                                       <Link to="/services">Prakruthie Lifestyle</Link></h4>
                                    <p>Lifestyle is India's leading fashion destination for the latest trends. Part of Dubai based retail and hospitality conglomerate - The Prakruthie Group, Lifestyle brings multiple categories including men, women and kids' apparel, footwear, handbags, fashion accessories and beauty under the convenience of a single roof.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-sm-6">
                              <div className="tp-about-author mb-30">
                                 <div className="tp-about-author-img">
                                    <img src="assets/img/about/about-author.png" className="img-fluid" alt="img not found"/>
                                 </div>
                                 <div className="tp-about-author-text">
                                    <h4 className="tp-about-author-text-title">Prakruthi S</h4>
                                    <span>Director - Prakruthie Makeup and Hair Academy</span>
                                 </div>
                              </div>
                           </div>
                           <div className="col-sm-6">
                              <div className="tp-about-number mb-30">
                                 <div className="tp-about-number-icon">
                                    <i className="flaticon-phone-call-1"></i>
                                 </div>
                                 <div className="tp-about-number-text">
                                    <span>Free Consultancy</span>
                                    <a href="tel:+91-6364777487">+91-6364777487</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default AboutArea;