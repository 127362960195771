import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import MehandiCourses from '../../../../components/Sliders/MehandiCourses';

const MehandiCourseParent = () => {
    const [isOpen, setOpen] = useState(false);
    return (
       <>
          <ModalVideo channel='youtube' 
             youtube={{ mute: 0, autoplay: 1 }}
             isOpen={isOpen}
             videoId="4yIWstRurms" onClose={() => setOpen(false)} />
             <MehandiCourses openVideo={setOpen} />
        </>
   );
};

export default MehandiCourseParent;