import React from 'react';
import 'react-modal-video/scss/modal-video.scss';
import { FaPlay } from 'react-icons/fa';
import { Fade } from 'react-reveal';

const MakeupCourses = ({ openVideo,videoId }) => {
    return (
        <> <div className="tp-single-slider tp-slider-height-two d-flex align-items-center swiper-slide" data-swiper-autoplay="5000">
                <div className="slide-bg home_two_slide_bg makeup-with-logo" data-background="assets/img/slider/slider-bg-2.jpg"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tp-slider-wrapper-two text-center mt-35">
                                <div className="tp-slider-video-btn-two mb-10" data-animation="fadeInUp" data-delay=".3s">
                                    <button onClick={() => openVideo(true)} className="venobox" data-autoplay="true" data-vbtype="video" href="https://youtube.com/">
                                        <i > <FaPlay className="text-white" /> </i>
                                    </button>
                                </div>
                                <div className="tp-slider-two z-index text-center opacity-backdrop">
                                    <Fade bottom>
                                        <h1 className="tp-slider-title-two mb-35 text-white"><span>Makeup Courses</span></h1>
                                        <h3 className="tp-slider-subtitle-two text-white">Learn the art of makeup with our comprehensive courses covering basic techniques, advanced skills, and industry trends. Join our academy now!</h3>
                                    </Fade>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MakeupCourses;