import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import NailArtCourses from '../../../../components/Sliders/NailArtCourses';

const NailArtCourseParent = () => {
    const [isOpen, setOpen] = useState(false);
    return (
       <>
          <ModalVideo channel='youtube' 
             youtube={{ mute: 0, autoplay: 1 }}
             isOpen={isOpen}
             videoId="I-gyu-905OU" onClose={() => setOpen(false)} />
             <NailArtCourses openVideo={setOpen} />
        </>
   );
};

export default NailArtCourseParent;