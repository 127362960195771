import React from 'react';

const HomeSingleTestimonial2 = ({image,name,title}) => {
    return (
        <>
            <div className="tp-testimonial-single swiper-slide z-index">
                <p className="mb-45">This academy is a gem! With diverse courses like mehandi and saree draping, it caters to everyone. The staff is polite, facilities top-notch, and they even sell cosmetics for convenience. Specialized training, flexible batches, and job assistance make it a one-stop destination for aspiring makeup artists.</p>
                <div className="tp-testimonial-author">
                    {/* <div className="tp-testimonial-author-img">
                        <img src={`assets/img/testimonial/testimonial-author-${image}.jpg`} className="img-fluid" alt="img not found" />
                    </div> */}
                    <div className="tp-testimonial-author-text">
                        <h4 className="tp-testimonial-author-text-name">{name}</h4>
                        <span className="tp-testimonial-author-text-designation">{title}</span>
                    </div>
                </div>
                <div className="tp-testimonial-qoute">
                    <img src="assets/img/icon/test-qoute.png" alt="img not found" />
                </div>
            </div>
        </>
    );
};

export default HomeSingleTestimonial2;