import { useCallback, useEffect, useRef } from "react";
import TawkTo from "tawkto-react";
//import { tawkPropertyID, tawkID, userRoleClinic, userRoleDoctor } from "../utility/constants";
//import { useHistory } from "react-router-dom";
//import { env } from "../config/index";
//import { useSelector } from "react-redux";
//import { dashboardRoute } from "../routes";

const Tawk = () => {
  const tawkRef = useRef(null);
  //const history = useHistory();
  //const currentUser = useSelector((state) => state.auth.userData) || {};
  //const showTawk = currentUser.role !== userRoleClinic && currentUser.role !== userRoleDoctor;
  //const isProductionEnv = env === "PROD";

  // const showOrHideTawk = useCallback(
  //   ({ pathname }) => {
  //     // console.log(
  //     //   "--- showOrHideTawk",
  //     //   showTawk,
  //     //   pathname === dashboardRoute.path,
  //     //   isProductionEnv
  //     // );
  //     if (!tawkRef.current) return;
  //     if (showTawk && pathname === dashboardRoute.path && isProductionEnv) {
  //       // console.log("--- show tawk");
  //       tawkRef.current.showWidget();
  //     } else {
  //       // console.log("--- hide tawk");
  //       tawkRef.current.hideWidget();
  //     }
  //   },
  //   [isProductionEnv, showTawk]
  // );

  // useEffect(() => {
  //   return history.listen((location) => {
  //     showOrHideTawk({ pathname: location.pathname });
  //   });
  // }, [history, showOrHideTawk]);

  useEffect(() => {
    if (tawkRef.current) return;
    //if (!isProductionEnv) return;
    const tawk = new TawkTo("6534db57a84dd54dc483c2ca", "1hdb7hju5");
    tawkRef.current = tawk;
    tawk.onLoad(() => {
      //showOrHideTawk({ pathname: history.location.pathname });
    });
    tawk.onStatusChange((status) => {
      console.log("--- tawk stats", status);
    });
    tawk.showWidget();
    return () => {
      tawk.hideWidget();
    };
  });//, [isProductionEnv, showOrHideTawk, history.location.pathname]);

  // console.log("--- isProductionEnv", isProductionEnv);

  return <span></span>;
};

export default Tawk;