import React from 'react';

const HomeSingleTestimonial1 = ({image,name,title}) => {
    return (
        <>
            <div className="tp-testimonial-single swiper-slide z-index">
                <p className="mb-45">I'm thrilled with my experience at this makeup academy! The clean environment, certified trainers, and easy access to cosmetics are fantastic. I love how they encourage experimentation in makeup and nail art. Their assistance in finding jobs and internships is invaluable. Definitely recommend to all beauty enthusiasts!</p>
                <div className="tp-testimonial-author">
                    {/* <div className="tp-testimonial-author-img">
                        <img src={`assets/img/testimonial/testimonial-author-${image}.jpg`} className="img-fluid" alt="img not found" />
                    </div> */}
                    <div className="tp-testimonial-author-text">
                        <h4 className="tp-testimonial-author-text-name">{name}</h4>
                        <span className="tp-testimonial-author-text-designation">{title}</span>
                    </div>  
                </div>
                <div className="tp-testimonial-qoute">
                    <img src="assets/img/icon/test-qoute.png" alt="img not found" />
                </div>
            </div>
        </>
    );
};

export default HomeSingleTestimonial1;