import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
// import { Swiper, SwiperSlide } from "swiper/react";
import { CgArrowLongRight,CgArrowLongLeft } from 'react-icons/cg';
import "swiper/css";
import "swiper/css/pagination"
// import Fade from 'react-reveal/Fade';
import SwiperCore, { Pagination,Navigation, Autoplay, A11y, EffectFade, EffectCards } from 'swiper';
import HomeTwoHeroSectionSingleSlide from '../../../../components/Sliders/SareeDrapingCourses';
import { Carousel } from 'react-bootstrap';
import MakeupCourses from '../../../../components/Sliders/MakeupCourses';
import HairDressingCourses from '../../../../components/Sliders/HairDressingCourses';
import NailArtCourses from '../../../../components/Sliders/NailArtCourses';
import MehandiCourses from '../../../../components/Sliders/MehandiCourses';
import SareeDrapingCourses from '../../../../components/Sliders/SareeDrapingCourses';
import Cosmetics from '../../../../components/Sliders/Cosmetics';
import MakeupCourseParent from '../ParentPages/MakeupCourseParent';
import HairDressingParent from '../ParentPages/HairDressingCourseParent';
import NailArtCourseParent from '../ParentPages/NailArtCourseParent';
import MehandiCourseParent from '../ParentPages/MehandiCourseParent';
import SareeDrapingCourseParent from '../ParentPages/SareeDrapingCourseParent';
import CosmeticsParent from '../ParentPages/CosmeticsParent';
import OnlineCourseParent from '../ParentPages/OnlineCourseParent';

// install Swiper modules
SwiperCore.use([Pagination,Navigation, Autoplay, A11y, EffectFade, EffectCards]);

const DashboardCarousal = () => {
   return (
      <>
         <section className="tp-slider-area fix">
            <div className="tp-slider-active swiper-container">

            <Carousel indicators={false} nextIcon={<div className="swiper-button-next slide-next">
               <i><CgArrowLongRight className="long_arrow" /></i> </div>}
               prevIcon={<div className="swiper-button-prev slide-prev">
               <i><CgArrowLongLeft className="long_arrow" /></i> </div>}
               >
                  <Carousel.Item>
                    <MakeupCourseParent />
                  </Carousel.Item>
                 <Carousel.Item>
                     <HairDressingParent />
                  </Carousel.Item>
                  <Carousel.Item>
                     <NailArtCourseParent/>
                  </Carousel.Item>
                  <Carousel.Item>
                     <MehandiCourseParent />
                  </Carousel.Item>
                  <Carousel.Item>
                     <SareeDrapingCourseParent/>
                  </Carousel.Item>
                  <Carousel.Item>
                     <CosmeticsParent />
                  </Carousel.Item>
                  <Carousel.Item>
                     <OnlineCourseParent />
                  </Carousel.Item>
               </Carousel>
            </div>
         </section>
      </>
   );
};

export default DashboardCarousal;