import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
// import Swiper core and required modules
import SwiperCore, { Navigation } from 'swiper';
import HomeSingleProject from '../../../../components/HomeSingleProject/MakeupCoursesProj';
import BridalMakeupProj from '../../../../components/HomeSingleProject/BridalMakeupProj';
import MakeupCoursesProj from '../../../../components/HomeSingleProject/MakeupCoursesProj';
import HairDressingProj from '../../../../components/HomeSingleProject/HairDressingProj';
import MehandiProj from '../../../../components/HomeSingleProject/MehandiProj';
import NailArtProj from '../../../../components/HomeSingleProject/NailArtProj';
import SareeDrapingProj from '../../../../components/HomeSingleProject/SareeDrapingProj';
import CosmeticsProj from '../../../../components/HomeSingleProject/CosmeticsProj';
SwiperCore.use([Navigation]);

const Projects = () => {
   return (
      <>
         <section className="tp-project-area white-bg position-relative">
            <div className="tp-project-shape"></div>
            <div className="tp-project-wrapper grey-bg pt-120 wow fadeInUp" data-wow-delay=".2s">
               <div className="section-title-wrapper text-center mb-55 grey-bg">
                  <h5 className="tp-section-subtitle common-yellow-shape section__sm__title mb-20">Some Completed Project</h5>
                  <h2 className="tp-section-title mb-20">Every Course is Different <br />Every Client special</h2>
               </div>
               <div className="tp-project-active swiper-container">
                  <Swiper
                     spaceBetween={30}
                     slidesPerView={1}
                     className='swiper-wrapper'
                     autoplay={{ delay: 1000 }}
                     breakpoints={{
                        550: {
                           slidesPerView: 2,
                        },
                        768: {
                           slidesPerView: 2,
                        },
                        1200: {
                           slidesPerView: 3,
                        },
                        1400: {
                           slidesPerView: 3,
                        },
                        1600: {
                           slidesPerView: 4,
                        },
                     }}
                     navigation={{ nextEl: '.brand-button-next', prevEl: '.brand-button-prev', }}
                     onSlideChange={() => console.log('slide change')}
                     onSwiper={(swiper) => console.log(swiper)}
                  >

                     <SwiperSlide>
                        <BridalMakeupProj project_img_num="1" title="Bridal Makeup" />
                     </SwiperSlide>

                     <SwiperSlide>
                        <MakeupCoursesProj project_img_num="2" title="Makeup Courses" />
                     </SwiperSlide>

                     <SwiperSlide>
                        <HairDressingProj project_img_num="3" title="Hair Dressing" />
                     </SwiperSlide>

                     <SwiperSlide>
                        <MehandiProj project_img_num="4" title="Mehandi Courses" />
                     </SwiperSlide>

                     <SwiperSlide>
                         <NailArtProj project_img_num="2" title="Nail Art Courses" />
                     </SwiperSlide>

                     <SwiperSlide>
                        <SareeDrapingProj project_img_num="3" title="Saree Draping Courses" />
                     </SwiperSlide>

                     <SwiperSlide>
                        <CosmeticsProj project_img_num="3" title="Cosmetics in Retail and Wholesale" />
                     </SwiperSlide>

                  </Swiper>

               </div>
            </div>
         </section>
      </>
   );
};

export default Projects;