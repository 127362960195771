import { useCallback, useEffect, useRef, useState } from "react";
import { isMobileOrTabletBrowser } from "../../utility/Helper";
import { useHistory } from "react-router-dom";
//import Config,{ env } from "../../config/index";
//import { useSelector } from "react-redux";
//import { dashboardRoute } from "../../routes";
import ReactGA from "react-ga4";
import './style.scss';
import whatsappIcon from './whatsapp.png';

const Whatsapp = () => {
    const [link, setLink] = useState("");
//const [isWhatappVisible,setWhatappVisible] = useState(true);
//const [flag, setflag] = useState(false);
//const history = useHistory();
//   const currentUser = useSelector((state) => state.auth.userData) || {};
//   const showWhatsapp =
//     currentUser.role !== userRoleClinic && currentUser.role !== userRoleDoctor;
//   const isProductionEnv = env === "PROD";
//   const showOrHideWhatsapp = useCallback(
//     ({ pathname }) => {
//       setWhatappVisible((showWhatsapp && pathname === dashboardRoute.path 
//         && isProductionEnv) ? true : false);
//     },
//     [isProductionEnv, showWhatsapp]
//   );
//setWhatappVisible(true);
//   useEffect(() => {
//     return history.listen((location) => {
//       showOrHideWhatsapp({ pathname: location.pathname });
//     });
//   }, [history, showOrHideWhatsapp]);

  useEffect(() => {
   /// if (!isProductionEnv) return;
    //showOrHideWhatsapp({ pathname: history.location.pathname });
    const whatsAppLink = isMobileOrTabletBrowser ? 'https://api.whatsapp.com/send' : 'https://web.whatsapp.com/send';
    const message = `Hi Prakruthie Makeup and Hair Academy ! I have a query on the courses,please get in touch!`;
    setLink(encodeURI(whatsAppLink + '?phone=' + "916364777487" + '&text=' + message));
   
  });
  //, [history.location.pathname,isMobileOrTabletBrowser, isProductionEnv, showOrHideWhatsapp]);
  const openWhatsappInNewTab = () => {
    ReactGA.send("Whatsapp");
    const newWindow = window.open(link, '_blank', 'noopener,noreferrer,nofollow');
  }
  //if(isWhatappVisible){
  return(
    <div className="cursor-pointer relative h-4.5 basic:h-5 xxxs:h-6.5 w-4.5 basic:w-5 xxxs:w-6.5 md:w-8.3 md:h-8.3" onClick={openWhatsappInNewTab}>
          <img
            src={whatsappIcon}
            layout="fill"
            alt="Whatsapp"
            className="whatsapp-icon"
          />
      </div>
  );
  //}
  //else
  //return <span></span>;
  
};

export default Whatsapp;
