import React from 'react';
import Footer from '../../../components/shared/Footer/Footer';
import Navigation from '../../../components/shared/Navigation/Navigation';
import AboutArea from './AboutArea/AboutArea';
import BannerArea from './BannerArea/BannerArea';
import Blogs from './Blogs/Blogs';
import ChoseArea from './ChoseArea/ChoseArea';
import CtaArea from './CtaArea/CtaArea';
import FactArea from './FactArea/FactArea';
import HeroSection from './HeroSection/HeroSection';
import PricingArea from './PricingArea/PricingArea';
import ProgressArea from './ProgressArea/ProgressArea';
import Projects from './Projects/Projects';
import ServiceArea from './ServiceArea/ServiceArea';
import Testimonial from './Testimonial/Testimonial';
import HomeTwoHeroSection from '../../HomeTwo/HomeTwo/DashboardCarousal/DashboardCarousal';
import {Helmet} from 'react-helmet';
import Tawk from '../../../components/Tawk/Tawk';
import Whatsapp from "../../../components/Whatsapp/Whatsapp";


const Home = () => {
   return (
      <>
          <Helmet>
                    <meta charSet="utf-8" />
                    <title>Top Makeup Academy in Bangalore | Professional Makeup Artist Courses | Nail Art | Permanent Makeup in Bangalore</title>
                    <link rel="canonical" href="https://prakruthie.com/" />
                    <meta name="keywords" content="makeup academy in bangalore,makeup in banglore,nail art in banglore,mehandi in banglore,
    hair coloring in banglore, hair dressing in banglore, hair cutting in banglore,
    prakruthi, prakruthi makeup,prakruthi makeover, prakruthie, prakruthie makeup, prakruthie academy, prakaruthie makeup academy, makeup academy in banglore,
    makeup, makeup courses,makeup course,makeup and hair courses, makeup courses in banglore,online makeup courses, online makeup courses in banglore,
    nail,nail art, nail art courses, online nail courses,online nail art courses, online nail art courses in banglore,
    mehandi,mehandi course,mehandi courses,mehandi courses in banglore,online mehandi courses,online mehandi courses in banglore,
    saree draping, saree draping courses, saree draping courses in banglore,
    hair,hair styling,hair styling courses, hair styling in banglore,hair styling courses in banglore,online hair styling courses,
    hair dressing,hair dressing courses, hair dressing courses in banglore,online hair dressing courses,online hair dressing,
    hair cutting,hair cutting courses, hair cutting courses in banglore,online hair cutting courses,
    hair coloring,hair coloring courses, hair coloring courses in banglore, online hair coloring courses in banglore,
    cosmetics, cosmetics wholesales in banglore, cosmetics selling, cosmetics retail, cosmetics wholesale,
    airbrush makeup, airbrush makeup in banglore, 
    bridal makeup, bridal maekup in banglore, bridal makeup artist,makeup artist,#1 makeup artist,
    south indian bridal makeup artist, muslim marriage makeup artist,
    rajasthani marriage makeup, bengali marriage makeup, kannnada makeup, south indian makeup, experienced makeup artist, 
    punjabi marriage makeup, tamil marriage makeup, christian marriage makeup,  
    rajasthani marriage makeup in banglore, bengali marriage makeup in banglore, kannnada marriage makeup in banglore,
    south indian marriage makeup, experienced makeup artist, 
    punjabi marriage makeup, tamil marriage makeup, christian marriage makeup,telugu marriage makeup,
    fantasy makeup artist, fantasy makeup,fantasy makeup in banglore,fantasy makeup courses in banglore
    sfx makeup, sfx makeup artist,sfx makeup in banglore,sfx makeup courses in banglore,
    experienced, remote,Makeup academy,Makeup courses,Nail art classes,Mehandi courses,Saree draping classes,Hair dressing courses,
    Cosmetology courses,Skin care courses,Weekend workshops,Photoshoots,Modeling assignments,Evening classes
    ,Online classes,Placement assistance,Bridal makeup services,Bridal nail art,Bridal Mehandi,Affordable makeup courses,
    Professional makeup training,Advanced makeup techniques,Nail art designs,Mehandi artistry,Saree draping techniques
    ,Hair styling tips,Cosmetology certification,Skin care treatments,Makeup artist certification,Beauty school programs,
    Hands-on makeup training,Creative nail art workshops,Traditional Mehandi designs,Fashion photoshoots
    Runway modeling opportunities,Evening makeup classes,Flexible online classes,Job placement support,
    Budget-friendly bridal packages,Elegant bridal makeup looks,Custom bridal nail designs,Intricate bridal Mehandi patterns,
    Makeup industry insights,Latest nail art trends,Cultural Mehandi traditions,Contemporary saree draping styles,
    Professional hair dressing techniques,Skincare essentials,Makeup tools and products,Bridal beauty consultations,
    Expert nail artistry,Trendsetting Mehandi designs,Personalized saree draping tutorials,Hair styling fundamentals,
    Skin care regimes,Makeup application tutorials,Nail art workshops for beginners,
    Mehandi workshops for enthusiasts,Saree draping mastery,Hair dressing mastery,
    Cosmetology techniques,Skin care expertise,Makeup career pathways,Nail artistry career options,
    Mehandi artist opportunities,Saree draping specialist roles,Hair dressing professional pathways,
    Cosmetology career prospects,Skin care specialist roles,Makeup business insights,
    Nail art entrepreneurship,Mehandi studio management,Saree draping boutique ventures,
    Hair dressing salon ownership,Cosmetology clinic management,Skincare product development,
    Makeup industry trends,Nail art innovations,Mehandi fashion evolution,Saree draping innovations,
    Hair dressing styles evolution,Cosmetology advancements,Skincare technology trends,
    Makeup tips and tricks,Nail art hacks,Mehandi secrets,Saree draping shortcuts,
    Hair dressing hacks,Cosmetology tips,Skincare routines,Makeup tutorials,Nail art tutorials,
    Mehandi tutorials,Saree draping tutorials,Hair dressing tutorials,Cosmetology tutorials,
    Skincare tutorials,Makeup academy near me,Nail art classes near me,Mehandi courses near me,
    Saree draping workshops near me,Hair dressing school near me" />
     <h1>Top Makeup Academy in Bangalore | Professional Makeup Artist Courses | Nail Art | Permanent Makeup in Bangalore</h1>
            </Helmet> 
           
            <Navigation/> 
            <HomeTwoHeroSection/>
            {/* <HeroSection /> */}
            <CtaArea />
            <AboutArea />
            {/* <BannerArea /> */}
            <ServiceArea />
            <ChoseArea />
            <FactArea />
            {/* <PricingArea /> */}
            <Testimonial />
            <Projects />
            <ProgressArea />
            {/* <Blogs /> */}
            <Footer/>
            <Tawk></Tawk>
            <Whatsapp></Whatsapp>

   
      </>
   );
};

export default Home;